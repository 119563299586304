import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "AppDialogTitle",
  uniqId: "UvmDdi",
})((theme) => {
  return {
    root: {
      display: "flex",
      gap: theme.spacing(1.25),
      padding: theme.spacing(3.75),
      ".MuiDialog-paperFullScreen &": {
        padding: theme.spacing(3),
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(2),
        },
      },
    },
    title: {
      flex: 1,
      "&:empty:before": {
        content: "'\\00a0'",
      },
    },
    startActions: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.25 / 2),
    },
    endActions: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.25 / 2),
    },
  };
});

export default useStyles;
