import { notificationConstants } from "@/utils/constants";
import { commonHelpers } from "@/utils/helpers";
import { useTranslation } from "next-i18next";

type GetAppNotificationPayloadPayload = {
  notificationType: string;
  beautyCenter?: Record<string, any> | null;
  referenceId?: string | null;
};

const useGetAppNotificationPayload = () => {
  const { t, i18n } = useTranslation();

  const getAppNotificationPayload = (
    payload: GetAppNotificationPayloadPayload
  ) => {
    const { notificationType, beautyCenter, referenceId } = payload;
    const beautyCenterName =
      beautyCenter?.[
        commonHelpers.generateFieldNameByLanguage(
          "name",
          i18n.language
        ) as keyof typeof beautyCenter
      ];

    switch (notificationType) {
      case notificationConstants.ASSIGNED_RESCHEDULED_APPOINTMENT_BY_ADMIN_TYPE: {
        return {
          title: t("newRescheduledAppointmentNotificationTitle_text", {
            beautyCenterName,
          }),
          body: t("newRescheduledAppointmentNotificationBody_text", {
            referenceId,
          }),
        };
      }
      default: {
        return {
          title: t("newAppointmentNotificationTitle_text", {
            beautyCenterName,
          }),
          body: t("newAppointmentNotificationBody_text", {
            referenceId,
          }),
        };
      }
    }
  };

  return getAppNotificationPayload;
};

export default useGetAppNotificationPayload;
